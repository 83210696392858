<template>
	<div class="app-container" style="background-color: #fff;">
		<!-- 	<div class="pruduct-info flex">
			<img src="../../icons/svg/404.svg" alt />
			<div class="spec-title">
				<div>规格库</div>
				<div>统一进行商品规格管理</div>
			</div>
		</div>-->
		<div class="flex" style="justify-content: space-between;">
			<buttonPermissions :datas="'specAdd'">
				<el-button
					type="primary"
					style="width:100px;margin-top: 20px;margin-bottom: 20px;"
					@click="addSpec"
				>新增规格</el-button>
			</buttonPermissions>
			<div>
				<el-input
					v-model="keyWord"
					placeholder="规格名称"
					style="width:250px;margin-right: 10px;"
					clearable
					@keyup.enter.native="keySearch"
				></el-input>
				<el-button type="primary" style="width:70px;" @click="keySearch">搜索</el-button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table
				:data="specList"
				style="width: 100%;margin-bottom: 20px;"
				row-key="id"
				border
				v-loading="listLoading"
			>
				<el-table-column prop="specName" label="属性规格项"></el-table-column>
				<el-table-column prop="values" label="属性值"></el-table-column>
				<el-table-column prop="visitCount" label="关联商品数"></el-table-column>
				<el-table-column label="操作" width="250">
					<template slot-scope="scop">
						<div style="display:flex;">
							<buttonPermissions :datas="'specEdit'">
								<el-button
									type="text"
									style="margin-left: 15px;"
									class="pointers"
									@click="editChild(scop.row)"
								>编辑</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'specDel'">
								<el-button
									type="text"
									style="margin-left: 15px;"
									class="pointers"
									@click="deleteCate(scop.row)"
								>删除</el-button>
							</buttonPermissions>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-pagination
			v-if="Total"
			style="margin:20px 0;float:right;"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="currentPage"
			:page-sizes="[10, 20, 30, 40, 50]"
			:page-size="pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="Total"
		></el-pagination>

		<!-- 新增规格 弹框 -->
		<el-dialog :visible.sync="specVisable" width="50%" title="添加规格">
			<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;">
				<el-form
					:model="ruleForm"
					ref="ruleForm"
					label-width="140px"
					class="demo-ruleForm"
					:validate-on-rule-change="false"
				>
					<el-form-item
						label="规格名称:"
						prop="specName"
						:rules="[{required:true,message:'规格名称不能为空',trigger: 'blur'}]"
					>
						<el-input
							v-model="ruleForm.specName"
							placeholder="请输入规格名称"
							maxlength="5"
							clearable
							style="width:300px"
						></el-input>
					</el-form-item>
					<el-form-item label="规格值:" :rules="[{required:true}]">
						<div style="display: flex;flex-direction: column;height: 400px;overflow: auto;width: 500px;">
							<div v-for="(item,index) in specValueList" :key="index">
								<el-input
									v-model="item.value"
									placeholder="请输入规格值"
									maxlength="30"
									clearable
									style="width:300px;margin-bottom: 20px;"
								>
									<span slot="suffix">{{item.value.length}}/30</span>
								</el-input>
								<i class="el-icon-delete" style="margin-left: 20px;" @click="removeValue(index)"></i>
							</div>
							<div>
								<el-button @click="addValue">添加规格值</el-button>
							</div>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<!-- 按钮区域 -->
			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="specVisable=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;" @click="saveChange()">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	getSpecList,
	saveSpec,
	deleteSpec
} from "../../api/goods.js"
import buttonPermissions from '@/components/buttonPermissions';
export default {
	components: {
		buttonPermissions
	},
	data () {
		return {
			specVisable: false,
			ruleForm: {
				specName: ''
			},
			loading: false,
			specList: [],
			keyWord: '',
			currentPage: 1,
			pageSize: 20,
			Total: 0,
			specValueList: [],
			specId: 0,
			listLoading: false
		}
	},
	created () {
		this.initData();
	},
	methods: {
		keySearch () {
			this.currentPage = 1;
			this.initData();
		},
		async initData () {
			try {
				this.listLoading = true;
				let data = {
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					specName: this.keyWord
				};
				let res = await getSpecList(data);
				if (res.success) {
					this.specList = res.data.records;
					this.Total = res.data.total;
					this.specList.map(item => {
						item.values = item.specValues.join(",");
						return item;
					})
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e);
			} finally {
				this.listLoading = false;
			}
		},
		async saveSpecData () {
			var list = [];
			this.specValueList.map(item => {
				list.push(item.value);
				return item;
			})
			try {
				let data = {
					specName: this.ruleForm.specName,
					specValueList: list
				};
				if (this.specId) {
					data.id = this.specId;
				}
				let res = await saveSpec(data);
				if (res.success) {
					this.loading = false;
					this.specVisable = false;
					let str = "";
					if (this.specId) {
						str = "规格值修改成功";
					} else {
						str = "规格值添加成功";
					}
					this.$message({
						showClose: true,
						type: 'success',
						message: str
					});
					this.initData();
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e);
			}
		},

		saveChange () {
			this.$refs['ruleForm'].validate(async (valid) => {
				if (valid) {
					try {
						if (this.specValueList.length) {
							for (let i = 0; i < this.specValueList.length; i++) {
								if (!this.specValueList[i].value) {
									this.$message({
										showClose: true,
										type: 'error',
										message: '规格值不能为空'
									});
									return;
								}
							}
							this.loading = true;
							this.saveSpecData();
						} else {
							this.$message({
								showClose: true,
								type: 'error',
								message: '规格值不能为空'
							});
						}
					} catch (e) {
						//TODO handle the exception
					}
				}
			});
		},
		// 切换显示条数
		handleSizeChange (val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.initData();
		},

		// 翻页
		handleCurrentChange (val) {
			console.log(`当前页: ${val}`);
			this.currentPage = val;
			this.initData();
		},
		addValue () {
			var obj = {
				value: ''
			}
			this.specValueList.push(obj);
		},
		removeValue (index) {
			this.specValueList.splice(index, 1);
			console.log(this.specValueList)
		},
		addSpec () {
			this.specId = 0;
			this.ruleForm.specName = '';
			this.specValueList = [];
			this.specVisable = true
		},
		editChild (row) {
			this.specId = row.id;
			this.ruleForm.specName = row.specName;
			var list = [];
			console.log(row.specValues)
			row.specValues.map(item => {
				var str = item;
				var obj = {
					value: str
				}
				list.push(obj);
			})
			this.specValueList = list;
			this.specVisable = true
		},
		async deleteCate (row) {
			try {

				this.$confirm('是否确认删除这个规格？删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					let res = await deleteSpec([row.id]);
					if (res.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '规格值删除成功'
						});
						this.initData();
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: '操作失败'
						});
					}
				})


			} catch (e) {
				//TODO handle the exception
			}
		}
	}
}
</script>

<style lang="less">
.pruduct-info > img {
	width: 80px;
	height: 80px;
	background-color: #f08047;
}
.spec-title {
	height: 70px;
	display: flex;
	margin-left: 10px;
	font-size: 14px;
	flex-direction: column;
	justify-content: space-between;
}
.flex {
	display: flex;
	align-items: center;
}
</style>
